import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React from 'react';

import { GeoAltFill } from 'react-bootstrap-icons';

import DeliveryTime from '../components/framework/delivery-time';

import Disclaimer from '../components/framework/disclaimer';
import CardVideo from '../components/framework/card-video';
import Link from '../components/framework/link';
import Prices from '../components/framework/prices';
import ServiceListItem from '../components/framework/service-list-item';

import Framework from '../components/framework';
import Seo from '../components/framework/seo';
import withLocation from '../middleware/withLocation';
import TravelInfo from '../components/framework/travel-info';

const LocationItem = ({ data, location }) => {
	const site = data.site.siteMetadata;
	const { t } = useTranslation('template_location');
	const types = [...new Set(data.location.products.map((value) => value.type))];
	let video = { mp4: null, webm: null };

	if (data.location.video_office) {
		for (let file of data.location.video_office) {
			if (file.indexOf('.mp4') !== -1) {
				video.mp4 = `${site.cdnUrl}${encodeURIComponent(file)}?alt=media`;
			} else if (file.indexOf('.webm') !== -1) {
				video.webm = `${site.cdnUrl}${encodeURIComponent(file)}?alt=media`;
			}
		}
	}

	const services = data.allService.edges
		.filter((value) => {
			if (value.node.partner.id != 'zample-nl') {
				return false;
			}
			return true;
		})
		.map((value) => {
			return value.node;
		});

	return (
		<Framework location={location}>
			<Seo
				description={t('meta_description', {
					location_address: data.location.address,
					location_name: data.location.name,
				})}
				tags={t('meta_keywords', {
					location_name: data.location.name,
				})}
				title={t('title', {
					location_name: data.location.name,
				})}
			/>

			<div className="container-fluid mb-0 mb-md-4 pt-3 pt-lg-5">
				<img
					alt=""
					className="receptions-item-img img-fluid mb-3 mb-lg-0 rounded"
					src={`${site.cdnUrl}${encodeURIComponent(
						data.location.image_header
					)}?alt=media`}
				/>
			</div>
			<section></section>
			<div className="container">
				<div className="row">
					<div className="col-md-8">
						<section id="clinic-title">
							<p className="text-primary mb-0">zample™</p>
							<h1 className="h2 mb-0">{data.location.name}</h1>
							<div className="row align-items-center">
								<div className="col-auto pe-0">
									<div className="title-small text-primary container-icon text-center">
										<GeoAltFill />
									</div>
								</div>
								<div className="col">
									<div className="title-small text-muted">
										{data.location.address}
									</div>
								</div>
							</div>
						</section>

						<div className="my-4">
							<h2 className="h4">
								{t('t2', { location_name: data.location.name })}
							</h2>
							<p className="text-muted">
								{t(`${data.location.translation_key}_description`)}
							</p>
						</div>

						<Prices types={types} />
						<section id="products">
							<div className="row">
								{services.map((item, index) => {
									return (
										<div className="col-md-6 mb-3" key={index}>
											<ServiceListItem item={item} />
										</div>
									);
								})}
							</div>
						</section>

						<Disclaimer />

						{(video.mp4 || video.webm) && (
							<div className="my-4">
								<CardVideo video_mp4={video.mp4} video_webm={video.webm} />
							</div>
						)}

						<section id="delivery-time">
							<div className="container bg-primary-gradient2 p-3 p-lg-4">
								<DeliveryTime />
							</div>
						</section>

						<div className="my-4">
							<h2 className="h4">{t('t3')}</h2>
							<TravelInfo />
						</div>
					</div>
					<div className="col-md-4">
						{data.location.image_office && (
							<img
								alt=""
								className="receptions-gallery-img img-fluid mb-3 mt-md-0 rounded"
								src={`${site.cdnUrl}${encodeURIComponent(
									data.location.image_office
								)}?alt=media`}
							/>
						)}
						{data.location.image_map && (
							<Link
								rel="noreferrer"
								target="_blank"
								to={data.location.address_url}
							>
								<img
									alt="Map"
									className="receptions-map img-fluid my-3 mt-md-0 rounded"
									src={`${site.cdnUrl}${encodeURIComponent(
										data.location.image_map
									)}?alt=media`}
								/>
							</Link>
						)}

						<div className="card mb-3 border-0 rounded-0">
							<div className="card-body p-4 small">
								<h4 className="title-small">{t('t4')}</h4>
								<Link
									rel="noreferrer"
									target="_blank"
									to={data.location.address_url}
								>
									<p className="mb-0">{data.location.address}</p>
								</Link>
							</div>
						</div>
						<div className="card mb-3 border-0 rounded-0">
							<div className="card-body p-4 small">
								<h4 className="title-small">{t('t5')}</h4>
								<p className="mb-0">{data.location.phone_number}</p>
								<p className="mb-0">{data.location.email}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Framework>
	);
};

LocationItem.propTypes = {
	data: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

export default withLocation(LocationItem);

export const pageQuery = graphql`
	query LocationBySlug($slug: String!) {
		site {
			siteMetadata {
				cdnUrl
			}
		}

		location(slug: { eq: $slug }) {
			address
			address_url
			email
			image_header
			image_map
			image_office
			name
			phone_number
			products {
				type
			}
			translation_key
			video_office
		}

		locales: allTranslation(filter: { id: { ne: "dummy" } }) {
			...translationFields
		}

		allService(
			filter: {
				active: { eq: true }
				locations: { elemMatch: { slug: { eq: $slug } } }
			}
		) {
			edges {
				node {
					currency {
						code
					}
					locations {
						id
					}
					name
					partner {
						id
					}
					price_from
					slug
					translation_key
				}
			}
		}
	}
`;
